<template>


    <!--====== Footer Section Start ======-->
    <footer class="footer-section footer-two bg-gray text-white rel z-1 pt-50">
        <div class="container">
            <div class="call-to-action bg-blue bgs-cover text-white rel z-1 ">
                <div class="row align-items-center">
                    <div class="col-xl-7 col-lg-6">
                        <div class="section-title mb-20">
                            <h2>Do you want to subscribe our newsletter?</h2>
                            <p>Leave your email and will keep you updated</p>
                        </div>
                    </div>
                    <div class="col-xl-5 col-lg-6">
                        <Form class="newsletter-form" autocomplete="off" @submit="emailsubmit" :validation-schema="schemaemail" v-slot="{ errors }">
                            <div class="newsletter-email">
                                <Field type="text" name="email" v-model="emailvalue"
                                       :class="{ 'is-invalid': errors.email }"
                                       class="form-control" maxlength="50" placeholder="Enter your email" />
                                <button type="submit" class="btn newsletter-btn">Sign up <i class="fas fa-angle-right"></i></button>
                            </div>
                            <div class="invalid-feedback">{{errors.email}}</div>
                        </Form>
                    </div>
                </div>
            </div>


            <div class="row justify-content-between">
                <div class="col-xl-3 col-sm-6 col-7 col-small">
                    <div class="footer-widget about-widget">
                        <div class="footer-logo mb-20">
                            <router-link :to="{ name: 'home'}">
                                <img src="../../assets/images/logo-white.png" alt="">
                            </router-link>
                        </div>
                        <p>Kotina asset management platform is designed to save your business time and money, by helping you get to market faster and sell/rent your assets quickly.</p>
                    </div>
                </div>
                <div class="col-xl-2 col-sm-4 col-5 col-small">
                    <div class="footer-widget link-widget">
                        <h4 class="footer-title">Company</h4>
                        <ul class="list-style-two">
                            <li><router-link href="javascript:;" to="/Aboutus">About Us</router-link></li>
                            <li><router-link href="javascript:;" to="/contactus">Contact Us</router-link></li>
                            <li><router-link href="javascript:;" to="/blog">Blogs</router-link></li>
                            <li><router-link href="javascript:;" to="/FAQ">FAQ's</router-link></li>
                            <li><router-link href="javascript:;" to="/Careers">Careers</router-link></li>
                            <li><router-link href="javascript:;" to="/testimonailList">Testimonials</router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-4 col-md-8">
                    <div class="footer-widget link-widget">
                        <h4 class="footer-title">Solutions</h4>
                        <ul class="list-style-two two-column">
                            <li><router-link to="/pricing">Pricing</router-link></li>
                            <li><router-link to="/TenantPortal">Tenant Portal</router-link></li>
                            <li><router-link to="/Leasing&Applications">Leasing & Applications</router-link></li>
                            <li><router-link to="/Marketing">Marketing</router-link></li>
                            <li><router-link to="/Communications">Communications</router-link></li>
                            <li><router-link to="/Accounting">Accounting</router-link></li>
                            <li><router-link href="javascript:;" to="/pressnews">Press Release</router-link></li>
                            <li><router-link href="javascript:;" to="/TermsandConditions"> Terms and Conditions</router-link></li>
                            <li><router-link href="javascript:;" to="/PrivacyPolicy">Privacy Policy</router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-3 col-md-4">
                    <div class="footer-widget contact-widget">
                        <h4 class="footer-title">Get in Touch</h4>
                        <ul class="list-style-three">
                          
                            <li><i class="fas fa-map-marker-alt"></i> <span>  {{Address.Address}},<br> {{Address.City}}, {{Address.StateName}}, {{Address.ZipCode}}  {{Address.CountryName}}</span></li>
                            <li><i class="fas fa-envelope-open"></i> <span><a href="">{{Address.Email}}</a></span></li>
                            <li><i class="fas fa-phone"></i> <span>Call : <a href="tel:(123) 456-7890">{{Address.PhoneNumber}}</a></span></li>
                        </ul>
                        <div class="social-style-one mt-25 flex-wrap" v-if="socialmedialinkList!=null && socialmedialinkList.length>0">
                            <template v-for="item in socialmedialinkList">
                                <a :href="[item.Url]" target="_blank"><i :class="[item.IconUsed.replace('fab', 'fab')]"></i></a>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="copyright-area text-center">
                <p>Copyright &#169; {{new Date().getFullYear()}} &amp; Kotina | All rights reserved.</p>
            </div>
        </div>
    </footer>
    <!--====== Footer Section End ======-->
</template>
<script>
    /* import UserFrontendService from '@/service/userfrontend/userfrontend.js';*/
    import UserFrontendService from '@/service/FrontendService.js'
    import { Form, Field } from 'vee-validate';
    import * as Yup from 'yup';
    export default {
        name: 'FooterView',
        props: [],
        components: {
            Form, Field
        },
        mounted() {

        },
        data() {
            const searchModel = {
                searchItem: null,
                status: -1,
                sortColumn: 'CreatedOn',
                sortDir: 'desc',
                pageIndex: 0,
                pageSize: 10,
                userId: localStorage.getItem("userId"),
                companyId: localStorage.getItem("SupcompanyId")
            }
            const schemaemail = Yup.object().shape({
                email: Yup.string().required('Email is required').email('Email is invalid').matches('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$', 'Email is invalid'),
            });
            return {
                Address: '',
                Compunydata: '',
                compunyDetails: '',
                socialmedialinkList: [],
                searchModel,
                emailvalue: null,
                schemaemail
            }
        },
        created() {
            this.getListOfSocialLinks();
            this.GetContactAddress();

        },
        methods: {

            getListOfSocialLinks() {
                this.isLoding = true;
                UserFrontendService.GetSocialLinkList(this.searchModel.searchItem, this.searchModel.sortColumn, this.searchModel.sortDir, this.searchModel.pageIndex, this.searchModel.pageSize, '00000000-0000-0000-0000-000000000000', '00000000-0000-0000-0000-000000000000',1).then(response => {
                    console.log('response', response);
                    this.socialmedialinkList = response.data.data;
                })
                setTimeout(() => { this.isLoding = false; }, 1000);

            },
            GetContactAddress() {
                debugger
                this.isLoading = true;
                UserFrontendService.GetContactusDetails(localStorage.getItem("SupcompanyId"), this.flag).then((response) => {

                    this.Compunydata = response.data[0].res == null ? null : JSON.parse(response.data[0].res)
                    var compunyDetails = this.Compunydata.jsondata == null ? null : JSON.parse(this.Compunydata.jsondata)
                    this.Address = compunyDetails[0]
                    var Googlemap = this.Compunydata.CompanyLocation == null ? null : JSON.parse(this.Compunydata.CompanyLocation)
                    this.Googlemap = Googlemap[0]
                    this.isLoading = false;
                }).catch((error) => {
                    this.isLoading = false;
                })
            },
        },
        
    }

</script>
