<template>
    <!--====== Hero Section Start ======-->
    <section class="hero-section-three rel z-2 pt-200 rpt-150 pb-130 rpb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-11">
                    <div class="hero-content-three rpt-15 rmb-75">
                        <h1 class="mb-15 wow fadeInUp delay-0-2s">
                            Intuitive and Powerful Software to Manage High-Value Assets
                        </h1>
                        <p class="wow fadeInUp delay-0-4s">
                            Kotina is a web-based system that offers solutions for managing various assets, including vacation homes, yachts, apartments, chartered planes, storage units, and more.

                        </p>
                        <div class="hero-btns mt-40 wow fadeInUp delay-0-8s">
                            <!--<a href="contact.html" class="theme-btn mb-15">Get Free Trial <i class="fas fa-arrow-right"></i></a>-->
                            <router-link to="/contactus" class="theme-btn mb-15">
                                Get Free Trial <i class="fas fa-arrow-right"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-7">
                    <div class="hero-image-three overlay wow fadeInLeft delay-0-4s">
                        <img src="../assets/images/hero/hero-image.png" alt="Hero" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Hero Section End ======-->
    <!--====== Feature Section Start ======-->
    <section class="feedback-section-three bg-blue rel z-1 py-115 rpy-85" style="">
        <div class="container">
            <div class="row">
                <div class="col-xl-4">
                    <div class="feedback-three-content mt-15 text-white wow fadeInUp delay-0-2s">
                        <div class="section-title mb-25">
                            <span class="sub-title">Features</span>
                            <h2>Manage it all with Kotina</h2>
                        </div>
                        <p>Kotina can help you capitalize on your assets.</p>
                        <div class="slider-dots-area mt-25"></div>
                    </div>
                </div>
                <div class="col-xl-8">
                    <div class="feedback-three-wrap">
                        <div class="feedback-item-two wow fadeInUp delay-0-4s">
                            <img src="../assets/images/vacationhome.jpg" alt="Vacation Home" class="img-fluid" />
                            <h3 id="person1" aria-hidden="true">Vacation Home</h3>
                            <p>Browse vacation homes, beach houses, apartments, condos, house boats, castles, farm stays - and everything in between.</p>
                        </div>
                        <div class="feedback-item-two wow fadeInUp delay-0-6s">
                            <img src="../assets/images/yacht.jpg" alt="Yacht" class="img-fluid" />
                            <h3 id="person1" aria-hidden="true">Yacht</h3>
                            <p>Wherever you want to go in the world, and whatever type of yacht charter you're looking for, we have your perfect sailing vacation.</p>
                        </div>
                        <div class="feedback-item-two wow fadeInUp delay-0-8s">
                            <img src="../assets/images/aircraft.jpg" alt="Aircraft" class="img-fluid" />
                            <h3 id="person1" aria-hidden="true">Aircraft</h3>
                            <p>Whether it's for a luxury escape, a business trip or a critical emergency, we offer a range of private charter solutions to match your unique requirements.</p>
                        </div>
                        <div class="feedback-item-two wow fadeInUp delay-1-0s">
                            <img src="../assets/images/storage-unit.jpg" alt="Storage Unit" class="img-fluid" />
                            <h3 id="person1" aria-hidden="true">Storage Unit</h3>
                            <p>Whether you are looking to store a few items or plan to pack up a house, we have you covered.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="services-section rel z-3 py-130 rpy-100" id="service">
        <div class="container">
            <div class="row justify-content-center text-center">
                <div class="col-xl-10 col-lg-10 col-md-10">
                    <div class="section-title mb-40">
                        <h2>I'm interested in a solution that can automate the management of both assets and services.</h2>
                    </div>
                </div>
            </div>
            <div class="services-tab">
                <ul class="nav nav-pills nav-fill mb-50">
                    <li class="nav-item">
                        <a class="nav-link active" data-toggle="tab" href="#crm">CRM</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#ticketing">Ticketing</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#loanportal">Loan Portal</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#vacationmanagement">Vacation Management</a>
                    </li>
                </ul>
                <div class="tab-content">
                    <div class="tab-pane fade active show" id="crm">
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <div class="services-image wow fadeInRight delay-0-2s" style="visibility: visible; animation-name: fadeInRight;">
                                    <img src="../assets/images/crm.png" alt="CRM">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="service-content rmb-55 wow fadeInLeft delay-0-2s" style="visibility: visible; animation-name: fadeInLeft;">
                                    <h3>Acquire new leads with Minimal effort</h3>
                                    <p>Our solution is designed to be simple, consistent, and reliable. It helps you acquire new leads, organize customer records, and trigger the following actions to move people effortlessly through the sales process.</p>
                                    <a href="#" class="read-more">Read More <i class="fas fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="ticketing">
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <div class="services-image">
                                    <img src="../assets/images/ticketing.png" alt="Ticketing">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="service-content rmb-55">
                                    <h3>Manage all your service requests in one place</h3>
                                    <p>
                                        Kotina leverages advanced rules, automation, and multichannel capabilities to handle each ticket efficiently. Our system allows you to track ticket traffic, monitor customer satisfaction, and stay on top of unassigned tickets from a dedicated, easy-to-use dashboard.
                                    </p>
                                    <a href="#" class="read-more">Read More <i class="fas fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="loanportal">
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <div class="services-image">
                                    <img src="../assets/images/localportal.png" alt="loanportal">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="service-content rmb-55">
                                    <h3>Streamline Your Loan and Lease Management Process</h3>
                                    <p>
                                        Our loan management solution is designed to be user-friendly, flexible, and customizable to fit seamlessly into your workflow. Kotina can also help with financing your assets, providing your customers a positive experience while effectively managing loans.
                                    </p>
                                    <a href="#" class="read-more">Read More <i class="fas fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="vacationmanagement">
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <div class="services-image">
                                    <img src="../assets/images/vacation-img.png" alt="Vacation">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="service-content rmb-55">
                                    <h3>Use Landingly to Create Amazing Landing Pages</h3>
                                    <p>At Kotina, we take great pride in providing our members with superior services. When you need optimal-scheduling for a multi-owner property, Kotina's AI driven scheduling can be the most-effective tool for you.</p>
                                    <a href="#" class="read-more">Read More <i class="fas fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="dots-circle-half">
            <img src="../assets/images/shapes/dots-circle-half.png" alt="shape">
        </div>
    </section>


    <section class="solutions-section-three text-white text-center rel bg-gray pt-130 rpt-100 z-1 pb-75 rpb-45">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-7 col-lg-8 col-md-10">
                    <div class="section-title mb-75">
                        <span class="sub-title">operations with one login</span>
                        <h2>All-in-one software. All the features.</h2>
                        <p>Kotina software combines multiple programs into one. Simplify your operations with one login. </p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-xl-4 col-md-6">
                    <div class="solution-item-three wow fadeInUp delay-0-2s">
                        <i class="fa fa-server bg-info text-white"></i>
                        <h3>Centralized</h3>
                        <p>Know the status of all assets at one glance and perform lease operations.</p>
                    </div>
                </div>
                <div class="col-xl-4 col-md-6">
                    <div class="solution-item-three wow fadeInUp delay-0-4s">
                        <i class="fas fa-dollar-sign bg-danger text-white"></i>
                        <h3>Automate Rents</h3>
                        <p>Kotina makes it easy to stay on top of critical financial data, such as interest rates, expenses, and rental income.</p>
                    </div>
                </div>
                <div class="col-xl-4 col-md-6">
                    <div class="solution-item-three wow fadeInUp delay-0-6s">
                        <i class="far fa-list-alt bg-success text-white"></i>
                        <h3>Taxes & Finances</h3>
                        <p>With Kotina, connecting to your bank is effortless. In just a few seconds, you can easily link your account and eliminate the risk of human error</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Dashboard Section Start ======-->
    <section class="dashboard-section rel z-1 py-130 rpy-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-xl-10 mx-auto">
                    <div class="row align-items-center">
                        <div class="col-xl-6 col-lg-6">
                            <div class="browswr-support-content rmb-55 wow fadeInRight delay-0-2s" style="visibility: visible; animation-name: fadeInRight;">
                                <div class="section-title">
                                    <span class="sub-title">Anytime, Anywhere</span>
                                    <h2>
                                        Kotina Mobile App To Manage Everything, Anytime, Anywhere
                                    </h2>
                                    <a href="#" class="theme-btn mt-3">Get Started <i class="fas fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 justify-content-center text-center">
                            <div class="dashboard-screenshot-wrap">
                                <div class="dashboard-screenshot-item">
                                    <img src="../assets/images/mobile1.png" alt="Dashboard Screenshot">
                                </div>
                                <div class="dashboard-screenshot-item">
                                    <img src="../assets/images/mobile2.png" alt="Dashboard Screenshot">
                                </div>
                                <div class="dashboard-screenshot-item">
                                    <img src="../assets/images/mobile0.png" alt="Dashboard Screenshot">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    </section>


    <!--====== Contact Section Start ======-->
    <section class="contact-section-two bg-lighter  rel z-1 pt-115 rpt-85 pb-130 rpb-55">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="contact-left-map h-100 pr-xl-5 rpb-20 wow fadeInLeft delay-0-2s">
                        <img src="../assets/images/happy-family.jpg" class="rounded"/>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="row justify-content-center text-center">
                        <div class="col-xl-12 col-lg-12 col-md-12">
                            <div class="section-title mb-50">
                                <h2>Let's get started!</h2>
                                <p>Talk with an expert and see Kotina in action.</p>
                            </div>
                        </div>
                    </div>
                    <Form id="contact-form-two" autocomplete="off" @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }" class="contact-form-two">
                        <div class="getStartedbx-Frm row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <Field type="text" name="firstname" v-model="firstname"
                                           :class="{ 'form-control' : true ,'is-invalid': errors.firstname }"
                                           maxlength="50" placeholder="First Name*" />
                                    <div class="invalid-feedback">{{errors.firstname}}</div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <Field type="text" name="lastname" v-model="lastname"
                                           :class="{ 'form-control' : true ,'is-invalid': errors.lastname }"
                                           maxlength="50" placeholder="Last Name*" />
                                    <div class="invalid-feedback">{{errors.lastname}}</div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <Field type="text" name="email" v-model="contactusData.email"
                                           :class="{ 'form-control' : true ,'is-invalid': errors.email }"
                                           maxlength="50" placeholder="Work Email*" />
                                    <div class="invalid-feedback">{{errors.email}}</div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <Field v-slot="{field }"
                                           v-model="contactusData.phone"
                                           name="phone"
                                           value="value">

                                        <input-mask name="phone"
                                                    v-model="contactusData.phone"
                                                    :model-value="field.value"
                                                    mask="(999) 999-9999"
                                                    :class="{ 'form-control' : true ,'is-invalid': errors.phone }"
                                                    placeholder="(___) ___-____"
                                                    type="text"></input-mask>
                                        <div class="invalid-feedback">{{errors.phone}}</div>
                                    </Field>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <Field type="text" name="companyname" v-model="contactusData.companyname"
                                           :class="{ 'form-control' : true ,'is-invalid': errors.companyname }"
                                           maxlength="50" placeholder="Company Name*" />
                                    <div class="invalid-feedback">{{errors.companyname}}</div>
                                </div>
                            </div>
                            <!--<div class="col-md-12">
                                <div class="form-group">
                                    <Field type="text" v-model="contactusData.subject" name="subject"
                                           :class="{ 'form-control' : true ,'is-invalid': errors.subject }"
                                           placeholder="Subject*" />
                                    <div class="invalid-feedback">{{errors.subject}}</div>
                                </div>
                            </div>-->
                            <div class="col-md-12">
                                <div class="form-group">
                                    <Field name="message" v-model="contactusData.message">
                                        <textarea v-model="contactusData.message"
                                                  :class="{ 'form-control' : true ,'is-invalid': errors.message }"
                                                  placeholder="Message*" />
                                    </Field>
                                    <div class="invalid-feedback">{{errors.message}}</div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <button type="submit" class="theme-btn">Get Demo</button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </section>
    <!--====== Feedback Section Start ======-->
    <section class="feedback-section-two rel z-1 py-130 rpy-100">
        <div class="container">
            <div class="row align-items-end mb-20">
                <div class="col-xl-6 col-lg-8">
                    <div class="section-title mb-20">
                        <span class="sub-title">Hear from our Customers</span>
                        <h2>What sets Kotina as all in one solution for you</h2>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-4">
                    <div class="slider-arrow-btns text-lg-right mb-30">
                        <button class="feedback-prev"><i class="fas fa-arrow-left"></i></button>
                        <button class="feedback-next"><i class="fas fa-arrow-right"></i></button>
                    </div>
                </div>
            </div>
            <div class="feedback-active" id="testSlide1">
                <div class="feedback-item-two wow fadeInUp delay-0-2s p-4" style="min-height:460px;" v-for="(testimonial,index) in testimonailList">
                    <!--<img src="../assets/images/member-1.jpg" alt="Logo" style="max-width:100px; border-radius:100%;" />-->
                    <img v-if="testimonial.imagefile !='' && testimonial.imagefile != null" :src="testimonial.imagefile" class="testimonial-img" />
                    <img v-else src="../assets/images/default.png" alt="" class="testimonial-img">
                    <p>{{$filters.TruncateStr( testimonial.description,150)}}</p>
                    <div class="feedback-author">
                        <i class="flaticon-quote-1"></i>
                        <div class="author-content">
                            <h3 class="p-0">{{testimonial.authorname}}</h3>
                            <span>{{$filters.dateFormat(testimonial.posteddate)}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="dots-circle-half"><img src="/img/dots-circle-half.68ba9ab2.png" alt="shape"></div>
    </section>
    <!--====== Feedback Section End ======-->
    <Loading v-model:active="isLoading">
    </Loading>
</template>

<script>

    import { Swiper, SwiperSlide } from 'swiper/vue';
    import { Form, Field } from 'vee-validate';
    import { Pagination, Navigation, Scrollbar, Autoplay } from 'swiper';
    import 'swiper/css/pagination';
    import 'swiper/css/navigation';
    import 'swiper/css/virtual';
    import service from '@/service/FrontendService.js'
    import * as Yup from 'yup';
    import InputMask from "@/views/shared/inputmask/inputmasktemplate.vue"
    import FrontendService from '../service/FrontendService';
    /* import VueSlickCarousel from 'vue-slick-carousel'*/
    // Import Swiper styles
    import 'swiper/css';

    export default {
        name: 'HomeView',
        components: {
            Form, Field, InputMask,
            Swiper,
            SwiperSlide
        },
        data() {
            const companyId = localStorage.getItem("SupcompanyId")
            const schema = Yup.object().shape({
                firstname: Yup.string().required('First name is required'),
                lastname: Yup.string().required('Last name is required'),
                phone: Yup.string().required('Phone is required'),
                companyname: Yup.string().required('Company Name is required'),
                //subject: Yup.string().required('Subject is required'),
                message: Yup.string().required('Message is required'),
                email: Yup.string().required('Email is required').email('Email is invalid').matches('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$', 'Email is invalid'),
            });
            const schemaemail = Yup.object().shape({
                email: Yup.string().required('Email is required').email('Email is invalid').matches('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$', 'Email is invalid'),
            });
            return {
                modules: [Pagination, Navigation, Scrollbar, Autoplay],
                schema,
                schemaemail,
                firstname: '',
                lastname: '',
                contactusData: {
                    id: null,
                    name: '',
                    companyname: '',
                    email: null,
                    phone: '',
                    //subject: '',
                    message: '',
                    type: '',
                    companyid: localStorage.getItem("SupcompanyId"),
                    pageUrl: window.location.href,
},

                testimonailList: [],
                emailvalue: null,
                isLoading: true,
                companyId
            }
        },
        created() {
            this.isLoading = false;
            this.getTestimonailList();
            this.SetSliderData();

        },
        updated() {
            window.scrollTo(0, 0);
        },
        methods: {
            SetSliderData() {
                if ($('.feedback-three-wrap').length) {

                    $('.feedback-three-wrap').slick({
                        dots: true,
                        infinite: true,
                        autoplay: true,
                        autoplaySpeed: 5000,
                        arrows: false,
                        appendDots: $('.slider-dots-area'),
                        centerMode: false,
                        speed: 1000,
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        responsive: [
                            {
                                breakpoint: 767,
                                settings: {
                                    slidesToShow: 1,
                                }
                            }
                        ]
                    });
                }
            },
            getTestimonailList() {
                this.isLoading = true;
                service.getTestimonailList().then((response) => {
                    if (this.$filters.IsNull(response.data) != null && response.data.statusCode == 200) {
                        console.log('data', response);
                        this.testimonailList = response.data.result
                    }
                    this.isLoading = false;
                }).catch((error) => {
                    this.isLoading = false;
                })
            },
            onSubmit() {
                debugger
                this.isLoading = true;
                this.contactusData.name = this.firstname + ' ' + this.lastname;
                this.contactusData.type = 'Request Demo';
                service.savecontactus(this.contactusData).then(res => {
                    debugger
                    this.isLoading = false;
                    if (res.data == 1) {
                        this.success('Your request has been submitted successfully. Our team will contact you soon.');
                        this.$router.push({
                            name: "thankyou",
                            state: {
                                routeMessage: {
                                    IsSuccess: true,
                                    displayMessage: 'Your request has been submitted successfully. Our team will contact you soon.'
                                }
                            }
                        });
                    }
                    else {
                        this.error('Something went wrong')
                    }
                }).catch(res => {
                    this.error("Something went wrong. Please contact site administrator.");
                    this.isLoading = false;
                })
            },
            async emailsubmit() {
                this.isLoading = true;
                var manageSubscribersModel = {
                    email: this.emailvalue
                }
                await FrontendService.AddSubscriber(manageSubscribersModel).then(Response => {
                    if (Response != null && Response.data.statusCode == 200 || Response.data.statusCode == 201) {
                        this.isLoading = false;
                        if (Response.data.statusCode == 200) {
                            this.$router.push({
                                name: "thankyou",
                                state: {
                                    routeMessage: {
                                        IsSuccess: true,
                                        displayMessage: 'You have successfully joined our subscriber list'
                                    }
                                }
                            }
                            )
                        }
                        else {
                            this.$router.push({
                                name: "thankyou",
                                state: {
                                    routeMessage: {
                                        Email: this.emailvalue,
                                        IsSuccess: false,
                                    }
                                }
                            });
                        }
                    }
                    else {
                        this.isLoading = false;
                        this.error('Bad req')
                    }
                }).catch(res => {
                    this.error("Something went wrong. Please contact site administrator.");
                    this.isLoading = false;
                })
            }
        }
    }
</script>

<style scoped>
</style>